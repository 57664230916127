<template>
  <div class="top-bar-guidance">
    <p>
      点击右上角，在<img
        :src="require('@/assets/common/safarilogo.png')"
        class="icon-safari"
      />
      浏览器打开
    </p>
    <p>可以继续访问本站哦~</p>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'

export default {
  data() {
    return {
      url: undefined
    };
  },
  created() {
    this.url = Base64.decode(this.$route.query.url)
    console.log(this.url)
    this.browser();
  },
  methods: {
    browser() {
      document
        .querySelector("body")
        .addEventListener("touchmove", function (event) {
          event.preventDefault();
        });
      window.mobileUtil = (function () {
        const UA = navigator.userAgent,
          isAndroid = /android|adr/gi.test(UA),
          isIOS = /iphone|ipod|ipad/gi.test(UA) && !isAndroid,
          isBlackBerry = /BlackBerry/i.test(UA),
          isWindowPhone = /IEMobile/i.test(UA),
          isMobile = isAndroid || isIOS || isBlackBerry || isWindowPhone;
        return {
          isAndroid: isAndroid,
          isIOS: isIOS,
          isMobile: isMobile,
          isWeixin: /MicroMessenger/gi.test(UA),
          isQQ: /QQ/gi.test(UA),
        };
      })(window, document);

      if (window.mobileUtil.isWeixin || window.mobileUtil.isQQ) {
        // var clipboard = new ClipboardJS("#BtnClick", {
        //   text: function () {
        //     $("#BtnClick").css({
        //       "background-color": "#2466f4",
        //       color: "white",
        //     });
        //     document.getElementById("BtnClick").innerHTML = "复制成功";
        //     return url;
        //   },
        // });
      } else {
        // document.getElementById("BtnClick").href = url;
        window.location.replace(this.url);
      }
    },
  },
};
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fff;
}
.top-bar-guidance {
  font-size: 15px;
  color: #fff;
  height: 70%;
  line-height: 1.8;
  padding-left: 20px;
  padding-top: 20px;
  background: url("../../assets/common/tiptop.png") top center no-repeat;
  background-size: contain;
}
.top-bar-guidance .icon-safari {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin: 0 0.2em;
}
</style>